import { type CreateControllerFn } from '@wix/yoshi-flow-editor';
import { initFormController } from '@wix/form-viewer/controller';
import { FORM_ID } from './constants';
import { type ControllerProps } from './types';
import { createSubmission } from '../../services/submission-service';
import { getFormIdFromUrl } from '../../services/get-form-id';

type SetProps = (props: Partial<ControllerProps>) => void;

const createController: CreateControllerFn = async ({ flowAPI }) => ({
  async pageReady() {
    const { controllerConfig } = flowAPI;
    const setProps: SetProps = controllerConfig.setProps;
    const setAppLoaded = () => {
      setProps({ appLoaded: true });
    };
    const formId = getFormIdFromUrl(flowAPI) || FORM_ID;
    const submitForm = async (values: any) => {
      return createSubmission(flowAPI.essentials.httpClient, formId, values);
    };

    setProps({
      setAppLoaded,
      submitForm,
      formId,
    });

    await initFormController(flowAPI, {
      formId,
      namespace: 'wix.form_example.form',
    });
  },
});

export default createController;
